import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
// import jsonp from 'jsonp';
import { jsonp } from 'vue-jsonp'
import Cookies from 'js-cookie'
// import { easEncrypt, aesDecrypt } from '../plugins/crypto'

// const headers = {
//     'X-Requested-With': 'XMLHttpRequest',
//     'Content-Type': 'application/x-www-form-urlencoded'
// }

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

axios.defaults.timeout = 100000

axios.interceptors.request.use(
    config => {
        // if (localStorage.token) {
        //     if (!config.params['access-token'] && localStorage.token) {
        //         config.params['access-token'] = localStorage.token
        //     }
        // }
        if (config.url.indexOf('http') == 0) {
            return config
        }

        if (config.url.indexOf('/vv/') > -1 || config.url.indexOf('/shop/') > -1) {
            config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        }

        // if (Vue.prototype.isToolMainSite()) {
        //     let CookieToken = Cookies.get('_vt')
        //     let localToken = localStorage.token
        //
        //     if (CookieToken && CookieToken !== localToken) {
        //         localStorage.token = CookieToken
        //         store.dispatch('upDateUser')
        //     }
        //     if (CookieToken) {
        //         config.headers['access-token'] = CookieToken
        //     } else {
        //         config.headers['access-token'] = localStorage.token
        //     }
        // } else {
        // }
        config.headers['access-token'] = localStorage.token

        if (config.method === 'post') {
            if (config.url.indexOf('/vv/') > -1 || config.url.indexOf('/shop/') > -1) {
                config.data = config.params
            } else {
                config.data = qs.stringify(config.params)
            }
            config.params = undefined
        }
        // config.params = easEncrypt(qs.stringify(config.params), key, key.substring(0, 16))
        return config
    },
    error => Promise.resolve(error.response || error)
)

axios.interceptors.response.use(
    response => {
        if (response.data.code !== 0 && response.data.msg) {
            // 报错后不弹出 Message 信息, 而是有自己处理错误的接口
            let noMessageArr = [
                "/tool/accounts/item-info",
                "/tool/accounts/wwfscreen",
                "/tool/accounts/searchpush",
                "/tool/accounts/acainixihuan",
                "/tool/accounts/kashouping-code",
                "/api/proxy/web/itemrank"
            ];

            if (response.config.url === '/tool/accounts/item-order') {
                Message.error('系统错误，请先用扫码模式')
            } else if (noMessageArr.includes(response.config.url)) {
                // console.log('不提示错误')
            } else if (response.config.url === "/api/proxy/web/searchplusfree") {
                // 实惠验号的免费查询有两个code不需要弹框
                if (+response.data.code === 100 || ((+response.data.code === 500) && (response.data.msg === "当日可用次数已达上限"))) {
                    // console.log('不提示错误')
                } else {
                    Message.error(response.data.msg)
                }
            } else if (response.data.msg === "请联系售后对接群进行充值") {
                //console.log('不提示错误')
            } else {
                // localStorage.compile 是配置SEO打包的参数, 只有在不是SEO的环境下才弹出错误框
                // 未登录或登录过期的情况下, 也不弹错误框, 而是弹出登录框
                if (!localStorage.compile) {
                    if (!(response.data.msg.indexOf("登录已过期") > -1 || +response.data.code === 401)) {
                        Message.error(response.data.msg)
                    }
                }
            }
        }
        if (response.data && response.data.msg && (response.data.msg.indexOf('登录已过期') > -1 || response.data.code === 401)) {
            if (response.data.msg.indexOf('token') > -1) {
                Message.error(response.data.msg)
            }            

            setTimeout(() => {
                store.dispatch('setLogout')
                setTimeout(() => {
                    window.openLogin()
                }, 1000);
            }, 2000)
        }

        if (response.data && response.data.msg && response.data.msg.indexOf('余额不足') > -1) {
            let whiteList = [
                'phone/charge/order'
            ]
            if (whiteList.some(item => response.config.url.includes(item))) {

            } else {
                if (window.openRecharge) {
                    window.openRecharge()
                }
            }

        }
        return response
    },
    error => {
        if (error.message && error.message.includes('timeout')) {
            Message.error('请求超时，请重试')
            return Promise.resolve(error)
        }
        if (error.response.status == 401 && error.response.statusText == 'Unauthorized') {
            // 需要登录的情况不弹出红色弹框, 而是直接弹出登录框
            // Message.error('您需要登录')
            setTimeout(() => {
                store.dispatch('setLogout')
                setTimeout(() => {
                    window.openLogin()
                }, 1000);
                // location.href = '/home'
            }, 2000)
            return
        }
        if (error.response && error.response.data && error.response.data.msg && error.response.data.msg.indexOf('登录已过期') > -1) {
            setTimeout(() => {
                store.dispatch('setLogout')
                setTimeout(() => {
                    window.openLogin()
                }, 1000);
            }, 2000)
        }
        console.log({ error });
        if (error.response && error.response.data.msg) {
            // localStorage.compile 是配置SEO打包的参数, 只有在不是SEO的环境下才弹出错误框
            if (!localStorage.compile) {
                Message.error(error.response.data.msg)
            }
        } else if (error.message) {
            if (error.code && error.code == "ECONNABORTED") {
                Message.error('连接超时')
            } else {
                // localStorage.compile 是配置SEO打包的参数, 只有在不是SEO的环境下才弹出错误框
                if (!localStorage.compile) {
                    Message.error(error.message)
                }
            }
            // if (error.message)
        }

        // 处理百度云报错信息
        let baiduYunErrCode = [501, 502, 503, 504, 522, 524];

        if (baiduYunErrCode.includes(error.response.status)) {
            Message.error('网络开小差了，请稍后重试')
        }

        return Promise.resolve(error.response || error)
    }
)

export const fetch = (url, params = {}, method = 'get') => {
    return new Promise((resolve, reject) => {
        axios({
            url,
            params,
            method
        }).then(res => resolve(res.data))
    })
}

export const fetchJsonp = (url, params = {}, method = 'get') => {
    return new Promise(async (resolve, reject) => {
        jsonp(url, { callbackQuery: "callbackParam", callbackName: "jsonpCallback" }, 30000).then((json) => {
            // 返回的jsonp数据不会放这里，而是在 window.jsonpCallback
            console.log({ jsonpRes: json });
            resolve(json);
        }).catch(err => {
            console.error({ jsonpErr: err });
            reject(err);
        })
    })
}

Vue.prototype.$fetch = fetch
Vue.prototype.$fetchJsonp = fetchJsonp
