<template>
    <div>
        <!-- 套餐价格展示 -->
        <div class="top-tip fc-333 fs-14 noborder">
            <!-- slot 表示如果功能有自己的特殊展示可以在自己的组件自定义, 当然也可以在下方特殊功能头部自定义 -->
            <slot :price="pricePreMonth">
                <!-- 套餐功能的头部展示 -->
                <div v-if="configData && Object.keys(configData).length > 0 && (!configData.is_proxy_site || (configData.is_proxy_site && configData.proxy_info.member_site_id)!=87)" v-cloak>
                    <div v-if="packageHeaderCode.includes(codeName)">
                        <i class="el-icon-s-opportunity"></i>
                        <!-- 已购买套餐和套餐过期是同一个意义 -->
                        <!-- 收费已购买套餐展示 -->
                        <span v-if="!packagePrice.isFree && packagePrice.is_buy">
                            <span>套餐用户每天免费使用<span class="fc-theme"> {{ packagePrice.tool_count }} </span>次，</span>
                            <span>剩余免费<span class="fc-theme"> {{ packagePrice.not_use_count }} </span>次</span>
                        </span>
                        <!-- 免费已购买套餐展示 -->
                        <span v-if="packagePrice.isFree && packagePrice.is_buy">
                            <!-- 免费无限次使用 -->
                            <span v-if="packagePrice.isWuxian">购买套餐包即可免费使用该功能</span>
                            <!-- 免费有限次使用 -->
                            <span v-if="!packagePrice.isWuxian">
                                <span>套餐用户每天免费使用<span class="fc-theme"> {{ packagePrice.tool_count }} </span>次，</span>
                                <span>剩余免费<span class="fc-theme"> {{ packagePrice.not_use_count }} </span>次</span>
                            </span>
                        </span>
                        <!-- 收费未购买套餐展示 -->
                        <span v-if="!packagePrice.isFree && !packagePrice.is_buy">购买套餐可低至<span class="fc-theme"> {{ packagePrice.unPrice }}</span> 点券一次</span>
                        <!-- 免费未购买套餐展示 -->
                        <span v-if="packagePrice.isFree && !packagePrice.is_buy">购买套餐包即可免费使用该功能</span>
                        <img class="tiyan-img-btn" :src="require('@/index/assets/sku_jk.gif')" alt="tips" @click="tiyanImgClick" />
                    </div>
                </div>

                <div style="display: flex; align-items: end;">
                    <!-- 已登录 -->
                    <div v-if="$store.state.phone">
                        <!-- 特殊功能的头部 -->
                        <div v-if="codeName === 'itemrank'">
                            <i class="el-icon-s-opportunity"></i>
                            <span>VIP或者套餐用户可以使用本功能，非会员请开通vip后使用 </span>
                        </div>
                        <div v-if="firstFlow === '1'">
                            <i class="el-icon-s-opportunity"></i>
                            <span v-if="+pricePreMonth.vip_point === 0">会员VIP<span class="fc-theme">免费使用</span></span>
                            <span v-else>会员VIP<span class="fc-theme">{{ pricePreMonth.vip_point }}</span>点券/次</span>
                            <span>，非会员VIP<span class="fc-theme">{{ pricePreMonth.point }}</span> 点券/次</span>
                            <span class="fc-666 marginL-10">生成淘口令需额外增加<span class="fc-theme">{{ configData.generate_password_point }}</span>点券</span>
                        </div>
                        <!-- 会员功能的头部展示 -->
                        <div v-else>
                            <i class="el-icon-s-opportunity"></i>
                            <!-- 实惠验号单独变更 -->
                            <span v-if="codeName === 'searchpluscharge'">
                                <span>免费查询是全部免费，推荐使用免费查询。升级查询</span>
                                <span>会员VIP<span class="fc-theme"> {{ pricePreMonth.vip_point }}</span> 点券/次，</span>
                                <span>非会员VIP<span class="fc-theme"> {{ pricePreMonth.point }}</span> 点券/次</span>
                            </span>
                            <span v-else>
                                <!-- 会员功能有免费次数的头部展示 -->
                                <span v-if="vipHeaderCode.includes(codeName)">
                                    <span>会员VIP每月免费使用</span>
                                    <span class="fc-theme"> {{ pricePreMonth.vip_month_given_num }} 次/月，</span>
                                    <span>当前剩余免费<span class="fc-theme"> {{ pricePreMonth.surplus_given_num }}</span> 次，</span>
                                </span>
                                <span>会员VIP<span class="fc-theme"> {{ pricePreMonth.vip_point }}</span> 点券/次，</span>
                                <span>非会员VIP<span class="fc-theme"> {{ pricePreMonth.point }}</span> 点券/次</span>
                            </span>
                        </div>

                        <div v-if="codeName == 'noTraceSupplementOrdinary'">
                            <i class="el-icon-s-opportunity"></i>
                            <span>
                                <span>生成稳定版二维码</span>
                                <span>会员VIP<span class="fc-theme"> {{ stableVersionPreMonth.vip_point }}</span> 点券/次，</span>
                                <span>非会员VIP<span class="fc-theme"> {{ stableVersionPreMonth.point }}</span> 点券/次</span>
                            </span>
                        </div>
                    </div>
                    <!-- 未登录 -->
                    <div v-else>
                        <!-- 会员有免费次数的未登录展示 -->
                        <span v-if="vipHeaderCode.includes(codeName)">
                            <span style="margin-right: 6px;">注册即可获得免费试用{{ pricePreMonth.month_given_num }}次</span>
                            <span v-if="!userData.is_vip" class="fc-theme point" @click="$bus.emit('openReg', configData)">点击注册</span>
                        </span>
                        <!-- 普通会员的未登录展示 -->
                        <span>
                            <span style="margin-right: 6px;">新用户注册后可联系客服领取会员</span>
                            <span v-if="!userData.is_vip" class="fc-theme point" @click="$bus.emit('openReg', configData)">点击注册</span>
                        </span>
                    </div>
                    <el-button v-if="!userData.is_vip" style="margin-left: 6px;" size="mini" type="danger" @click="$bus.emit('openVip', configData)">开通VIP</el-button>
                </div>
            </slot>
        </div>
        <!-- 单个套餐购买 -->
        <div v-if="configData && Object.keys(configData).length > 0 && (!configData.is_proxy_site || (configData.is_proxy_site && configData.proxy_info.member_site_id)!=87)" v-cloak>
            <div v-show="showPackageOne.includes(codeName)" class="package-img" @click="goBuy">
                <img :src="showPackageImg[codeName]" alt="packageImg" />
                <span class="money">{{ calcDiv(packagePrice.unPrice, 100) }}元/次</span>
            </div>
        </div>
        <buy-package-one v-if="buyPackageOneDialog.show" v-model="buyPackageOneDialog.show" :ex-data="buyPackageOneDialog.exData" @buySuccess="buySuccess"></buy-package-one>
    </div>
</template>

<script>
import { mapState } from "vuex";
import BuyPackageOne from "./_dialog/BuyPackageOne";
import { calcAdd, calcDiv, calcMul } from '@/index/plugins/calc'

export default {
    name: "TopPrice",
    components: {
        BuyPackageOne,
    },
    props: {
        code: {
            type: String,
        },
        firstFlow: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            codeName: this.code,
            pricePreMonth: {},
            stableVersionPreMonth: {},  // 特殊情况：稳定版补单价格
            packagePrice: {},
            // 套餐功能头部的 code, 由接口获取
            packageHeaderCode: [],
            // 会员功能有免费次数的头部的 code
            vipHeaderCode: ["taoguess", "searchpluscharge", "searchplus", "dsr", "searchplusjichu", "tao20w", "taosuggest", "usertag", "pddforcescreen", "unioncomputed", "fangpitu", "wwfscreen", "itemorder"],
            // 展示单个套餐功能的 code, 注意, 用它打开购买的时候后面要拼接 20, 即 searchplus 在打开购买的时候需要传递 searchplus20
            showPackageOne: ["searchplus", "searchplusjichu", "acainixihuan", "wwfscreen", "searchpush", "aweixiangqing"],
            // 展示单个套餐功能的 img
            showPackageImg: {
                "searchplus": require("@/index/assets/package_img/旺旺照妖镜6.gif"),
                "searchplusjichu": require("@/index/assets/package_img/大数据验号旺旺照妖镜6.gif"),
                "acainixihuan": require("@/index/assets/package_img/猜你喜欢推送6.gif"),
                "wwfscreen": require("@/index/assets/package_img/旺旺打标6.gif"),
                "searchpush": require("@/index/assets/package_img/手淘搜索推送6.gif"),
                "aweixiangqing": require("@/index/assets/package_img/手淘微详情6.gif"),
            },
            buyPackageOneDialog: {
                show: false,
                exData: {},
            },
        };
    },
    computed: {
        ...mapState(["userData", "configData", "phone"])
    },
    watch: {
        phone(newValue) {
            if (newValue) {
                this.priceLimit();
            }
        },
        code(newCode, oldCode) {
            this.codeName = newCode
            this.priceLimit()
        }
    },
    mounted() {
        this.priceLimit();
        this.getPackagePrice();
    },
    methods: {
        calcAdd,
        calcDiv,
        calcMul,

        async priceLimit() {
            let codeName = this.codeName;

            try {
                const res = await this.$fetch("/api/proxy/web/tool/detail", {
                    name: codeName,
                });
                this.pricePreMonth = res.data;
            } finally {
            }

            if (codeName == 'noTraceSupplementOrdinary') {
                // 稳定版补单价格
                try {
                    const res = await this.$fetch("/api/proxy/web/tool/detail", {
                        name: 'noTraceSupplementOrdinaryV1',
                    });
                    this.stableVersionPreMonth = res.data;
                } finally {
                }
            } else {
                this.stableVersionPreMonth = {}
            }
        },

        async getPackagePrice() {
            try {
                // 先请求获取所有的套餐 code 码
                const packageRes = await this.$fetch("/api/proxy/getPackageCode", {});
                this.packageHeaderCode = packageRes.data.total;

                // 如果当前 code 在套餐 code 码中, 则获取其套餐价格
                if (packageRes.data.total.includes(this.codeName)) {
                    this.getPopup(false)
                }
            } finally {

            }
        },

        async getPopup(needCheck){
            if (needCheck && !this.packagePrice.is_buy) {
                return false;
            }

            try {
                const res = await this.$fetch("/api/proxy/getPopup", {
                    tool_code: this.codeName,
                });

                this.packagePrice = res.data;
            } catch(e){
                console.error(e)
            }
        },

        goBuy() {
            this.buyPackageOneDialog.exData = {
                code: this.codeName + "20",
            };
            this.buyPackageOneDialog.show = true;
        },

        tiyanImgClick() {
            window.open("/buy-package");
        },

        buySuccess() {
            this.buyPackageOneDialog.show = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.tiyan-img-btn {
    position: relative;
    bottom: 1px;
    height: 16px;
    margin-left: 6px;
    cursor: pointer;
}

.package-img {
    width: 600px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    img {
        width: 100%;
    }

    .money {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: 180px;
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
        color: #fff;
    }
}
</style>
