<template>
    <div class="nav-header">
        <!-- 顶部 banner -->
        <div v-if="$store.state.phone && $store.state.userData.username">
            <router-link to="/store/list" style="
                width: 100%;
                height: 70px;
                background: url(http://image.vv-tool.com/images/8b7ed0717211a508d0745ab31f9a79ae.gif) no-repeat center;
                display: block;
            "></router-link>
        </div>
        <div v-else style="width: 100%; height: 70px;"></div>
        <div class="header flex flex-between" v-if="isMainSite || configData.proxy_info"
            :style="{ 'background-color': configData.proxy_info && configData.proxy_info.site_theme ? configData.proxy_info.site_theme : '#2c4167' }">
            <!-- Logo -->
            <div class="img fc-fff flex"
                :style="{ 'background-color': configData.proxy_info && configData.proxy_info.site_theme ? configData.proxy_info.site_theme : !isMainSite ? '#2c4167' : '#f13d63' }">
                <img
                    :src="configData.proxy_info && configData.proxy_info.site_logo && configData.proxy_info.site_logo.indexOf('/') > -1 ? (configData.proxy_info.site_logo.indexOf('//') > -1 ? configData.proxy_info.site_logo : 'http://' + configData.static_url + '/' + configData.proxy_info.site_logo) : require('../assets/img/logo.png')" />
            </div>
            <!-- 顶部菜单 -->
            <div class="flex-1 flex fs-18 paddingL-50">
                <template v-for="(item, index) in topNavFit">
                    <a :key="index" class="fc-fff top-nav" v-if="item.name == 'API接口' && isMainSite" target="_blank"
                        href="http://doc.vv-tool.com">API接口</a>
                    <a :key="index" class="fc-fff top-nav"
                        v-else-if="item.name == 'API接口' && !isMainSite && configData.proxy_info && configData.proxy_info.site_doc_host"
                        target="_blank"
                        :href="configData.proxy_info.site_doc_host.indexOf('//') > -1 ? configData.proxy_info.site_doc_host : `//${configData.proxy_info.site_doc_host}`">API接口</a>
                    <a :key="index" v-else :target="item.name == '首页' ? '' : '_blank'" class="fc-fff top-nav"
                        :class="{ 'header-extra': item.extra ? true : false }" style='cursor: pointer;'
                        @click='topNavClick(item)'>{{ item.name }}
                        <img v-if="item.name == '礼品商城'" class="nav-it iconhot" :src="require('../assets/img/hot.gif')"
                            alt />
                    </a>
                </template>
                <!-- 放开白名单指定的分站 -->
                <a v-if="showScrm" class="fc-fff top-nav" href="javascript:;" @click="goWmt">打单软件</a>
            </div>
            <div v-if="$store.state.phone && $store.state.userData.username" class="flex align-center right">
                <div class="my-money">
                    <span>可用点券：{{ $store.state.userData.surplus_total_point }}点</span>
                </div>
                <!-- <el-button type="primary" size="small" @click="$router.push('/recharge')">充值</el-button> -->
                <el-button type="primary" size="small" @click="openRecharge">充值</el-button>
                <div v-if="$store.state.userData.is_vip && $store.state.userData.active_vip_end_time" class="my-money">
                    <span>会员到期：剩余{{ showtime($store.state.userData.active_vip_end_time * 1000, true) }}</span>
                </div>
                <el-button v-if="$store.state.userData.is_vip && $store.state.userData.active_vip_end_time" type="primary"
                    size="small" @click="$router.push('/vip')">续费</el-button>
                <div class="name">
                    <img v-if="$store.state.userData.is_vip" class="top-vip" :src="require('../assets/vip_new.png')" alt />
                    <el-dropdown class="drop" size="small" @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{ $store.state.phone }}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="personCenter">
                                <span>个人中心</span>
                            </el-dropdown-item>
                            <el-dropdown-item command="newsCenter">
                                <span>消息中心</span>
                                <el-badge :value="12" />
                            </el-dropdown-item>
                            <el-dropdown-item command="logout">
                                <span>退出登录</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div v-else class="align-center right fs-14">
                <div>
                    未登录，点击
                    <span class="marginL-5 fc-theme pointer" @click="modal.login = true">登录</span>
                    <span class="fc-theme marginL-5 pointer" @click="modal.register = true">注册</span>
                </div>
            </div>
        </div>
        <div class="sider top140" :class="{ 'sider-show': mobile_menu }" v-if="isMainSite || configData.proxy_info"
            :style="{ 'background-color': configData.proxy_info && configData.proxy_info.site_theme ? configData.proxy_info.site_theme : '#2c4167' }"
            v-loading="navLoading">
            <el-menu ref="siderNav" class="nav-sider" text-color="#fff" :unique-opened="true"
                :default-active="$route.meta.activeMenu ? $route.meta.activeMenu : $route.path" @open="handleOpen"
                @close="handleClose" :router="true">
                <!-- <div v-for="(item, index) in navList" :key="index"> -->
                <div v-for="(item, index) in navListFilter" :key="index">
                    <!-- 没有子菜单 -->
                    <el-menu-item v-if="!item.children || item.children.length === 0" :index="item.href">
                        <i :class="item.icon"></i>
                        <span>{{ item.name }}</span>
                    </el-menu-item>
                    <!-- 有子菜单 -->
                    <el-submenu v-else :index="item.href">
                        <template slot="title">
                            <i :class="item.icon"></i>
                            <span>{{ item.name }}</span>
                        </template>
                        <template v-for="(cItem, cIndex) in item.children">
                            <!-- 授权验号需要是会员且消费满足一定条件才能展示出来 -->
                            <template v-if="item.name === '授权验号'">
                                <el-menu-item v-if="!!+userData.is_authorize" :key="cIndex" :index="item.href">{{ item.name
                                }}</el-menu-item>
                            </template>
                            <el-menu-item v-else :key="cIndex" :index="cItem.href">
                                <span>{{ cItem.name }}</span>
                                <!-- 处理热门图标 -->
                                <img v-if="cItem.name === '商品列表'" class="nav-it iconhot"
                                    :src="require('../assets/img/hot.gif')" alt />
                                <img v-if="cItem.name === '拼多多打标'" class="nav-it iconhot"
                                    :src="require('../assets/img/hot.gif')" alt />
                                <img v-if="cItem.name === '旺旺强行打标'" class="nav-it iconhot"
                                    :src="require('../assets/img/ms.gif')" alt />
                                <img v-if="cItem.name === '指数换算'" class="nav-it" :src="require('../assets/img/sycm.gif')"
                                    alt />
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </div>
            </el-menu>
        </div>
        <div v-show="false" class="show-meun" @click="mobile_menu = true">
            <i class="el-icon-s-operation"></i>
        </div>
        <div v-if="mobile_menu" @click="mobile_menu = false" class="menu-fade"></div>
        <!-- <el-button class="btn-service" type="primary" size="medium"
            @click="modal.service = true" icon="el-icon-service" circle>
        </el-button>-->
        <el-card class="service point fc-fff">
            <div class="flex">
                <div class="scaling marginR-10" @click="showService = !showService">
                    在线客服
                    <i :class="[showService ? 'el-icon-arrow-right' : 'el-icon-arrow-left']" class="fw-bold"></i>
                </div>
                <transition name="slide-service">
                    <div class="service-card" v-if="showService">
                        <div class="wx" @click="modal.service = true">
                            <img :src="require('@/index/assets/img/wx.png')" />
                            微信客服
                        </div>
                        <div class="qq" @click="modal.service = true">
                            <img :src="require('@/index/assets/img/qq.png')" />
                            QQ客服
                        </div>
                    </div>
                </transition>
            </div>
        </el-card>
        <login v-if="modal.login" v-model="modal.login" :ex-data="modalInfo.login" @forget="modal.forget = true"
            @success="loginSuccess"></login>
        <forget @success="modal.login = false, modal.forget = false" v-if="modal.forget" v-model="modal.forget"></forget>
        <register v-if="modal.register" v-model="modal.register" @success="regSuccess"></register>
        <service v-if="modal.service" v-model="modal.service"></service>
        <socket ref="socket"></socket>
        <qr-code v-if="qrCodeDialog.show" v-model="qrCodeDialog.show" :ex-data="qrCodeDialog.exData"
            ref="qrCodeRef"></qr-code>
        <push-qr-code v-if="pushQrCodeDialog.show" v-model="pushQrCodeDialog.show" :ex-data="pushQrCodeDialog.exData"
            ref="pushQrCodeRef"></push-qr-code>
    </div>
</template>

<script>
/* eslint-disable */
import login from './_dialog/login'
import forget from './_dialog/forget'
import register from './_dialog/register'
import service from './_dialog/service'
import { mapState } from 'vuex'
import { showtime } from '../plugins/timediff'
import Socket from './socket.vue'
import QrCode from './_dialog/QrCode'
import PushQrCode from './_dialog/PushQrCode'

export default {
    // mixins: [appConfig],
    components: {
        login,
        forget,
        register,
        service,
        Socket,
        QrCode,
        PushQrCode,
    },
    computed: {
        ...mapState(['userData', 'loginModal', 'configData']),
        isMainSite() {
            return !(process.env.NODE_ENV === 'production' && process.env.VUE_APP_MODE === 'clone')
        },
        topNavFit() {
            // 如果请求菜案 code 出错了, 则直接返回所有菜单
            if (this.navShowListHasErr) {
                return this.topNav;
            }

            let topNav = this.topNav
            let navShowList = this.navShowList;
            let hasShowList = [];

            topNav.forEach((item, index) => {
                if (!item.showCode || navShowList.includes(item.showCode)) {
                    hasShowList.push(item)
                }
            })

            return hasShowList;
        },
        navListFilter() {
            // 如果请求菜案 code 出错了, 则直接返回所有菜单
            if (this.navShowListHasErr) {
                return this.navList;
            }

            let navList = this.navList;
            let navShowList = this.navShowList;
            let hasShowList = [];

            navList.forEach(item => {
                hasShowList.push({
                    href: item.href,
                    icon: item.icon,
                    name: item.name,
                    children: [],
                })
            })

            navList.forEach((item, index) => {
                item.children.forEach(cItem => {
                    if (!cItem.showCode || navShowList.includes(cItem.showCode)) {
                        hasShowList[index].children.push(cItem)
                    }
                })
            })

            return hasShowList;
        },
        showScrm() { // 是否展示进入 scrm 的入口
            let whiteList = ["www.taobao2622.com", "tool.aicxwl.com", "www.haodanzi.vip"]; // 允许打开 scrm 入口的白名单 url

            let has = whiteList.find(item => {
                if (window.location.href.includes(item)) {
                    return true
                }
            })

            // window.location.href 包含了白名单中某个项, 且满足后端条件, 那表示允许展示 scrm
            return !!has && !!+this.userData.is_authorize
        },
    },
    data() {
        return {
            modal: {
                login: false,
                forget: false,
                register: false,
                service: false
            },
            modalInfo: {
                login: "",
            },
            showService: true,
            menu_show: false,
            mobile_menu: false,
            topNav: [
                {
                    href: '/elTool',
                    name: '首页'
                },
                // {
                //     href: '/home',
                //     extra: true,
                //     name: '旺旺照妖镜'
                // },
                {
                    href: '/marking',
                    extra: true,
                    name: '旺旺强行打标',
                    showCode: "marking"
                },
                {
                    href: '/pddScreen',
                    extra: true,
                    name: '拼多多打标',
                    showCode: "pddScreen"
                },
                // {
                //     href: '/keyRank',
                //     extra: true,
                //     name: '关键字排名'
                // },
                {
                    href: '/flow/taobao',
                    name: '流量提升',
                    showCode: "pddScreen"
                },
                {
                    href: '/store/list',
                    name: '礼品商城',
                    showCode: "pddScreen"
                },
                {
                    href: '/interface',
                    name: 'API接口',
                },
                {
                    href: "/agent-join",
                    name: "代理加盟"
                },
                // {
                //     href: '/noOpen',
                //     name: '电商知识库'
                // },
                // {
                //     href: '/personCenter',
                //     name: '用户中心'
                // }
            ],
            navLoading: false,
            navShowList: [],
            navShowListHasErr: false, // 请求菜单 code 是否出错
            navList: [
                {
                    href: '/home',
                    icon: 'el-icon-menu',
                    name: '验号中心',
                    children: [
                        {
                            href: "/ww-label-weight",
                            name: "旺旺标签权重",
                            showCode: "ww-label-weight"
                        },
                        {
                            href: '/dy-check',
                            name: '抖音验号',
                            showCode: "dy-check"
                        },
                        {
                            href: '/authCheck',
                            name: '授权验号'
                        },
                        {
                            href: '/home',
                            name: '旺旺照妖镜',
                            showCode: "home"
                        },
                        {
                            href: '/weightpush',
                            extra: true,
                            name: '搜索权重推送',
                            showCode: "weightpush"
                        },
                        {
                            href: '/tagSearch',
                            extra: true,
                            name: '淘宝号标签查询',
                            showCode: "tagSearch"
                        },
                        {
                            href: '/goodsAnalysis',
                            extra: true,
                            name: '竞品数据透析',
                            showCode: "goodsAnalysis"
                        },
                        {
                            href: '/traceless',
                            extra: true,
                            name: '无痕做单',
                            showCode: "traceless"
                        },
                        {
                            href: '/validPicture',
                            extra: true,
                            name: '防P图验号',
                            showCode: "validPicture"
                        },
                        {
                            href: '/weightSearch',
                            extra: true,
                            name: '搜索词权重查询'
                        },
                        {
                            href: '/surmise',
                            extra: true,
                            name: '猜你喜欢入池'
                        },
                        {
                            href: '/transformData',
                            extra: true,
                            name: '指数换算',
                            showCode: "transformData"
                        },
                        {
                            href: '/tbguest',
                            extra: true,
                            name: '淘宝客订单检测',
                            showCode: "tbguest"
                        },
                        {
                            href: '/checkNum/dsrCal',
                            extra: true,
                            name: 'DSR智能计算器',
                            showCode: "checkNum_dsrCal"
                        },
                        {
                            href: '/taokouling',
                            extra: true,
                            name: '淘口令生成',
                            showCode: "taokouling"
                        },
                        {
                            href: '/appraise',
                            extra: true,
                            name: '宝贝评价分析',
                            showCode: "appraise"
                        },
                        {
                            href: '/lexicon',
                            extra: true,
                            name: 'TOP20w词库',
                            showCode: "lexicon"
                        },
                        {
                            href: '/lexRecommend',
                            extra: true,
                            name: '搜索下拉推荐词',
                            showCode: "lexRecommend"
                        },
                        {
                            href: '/lexTaobaoTag',
                            extra: true,
                            name: '旺旺搜索词推荐',
                            showCode: "lexTaobaoTag"
                        },
                        {
                            href: '/keyRank',
                            name: '关键字排名',
                            showCode: "keyRank"
                        },
                        {
                            href: '/checkAccount',
                            extra: true,
                            name: '大数据验号',
                            showCode: "checkAccount"
                        },
                        {
                            href: '/taokit',
                            extra: true,
                            name: '免费工具箱',
                            showCode: "taokit"
                        },
                    ]
                },
                {
                    href: '/giftShop',
                    icon: 'el-icon-s-platform',
                    name: '礼品商城',
                    children: [
                        {
                            href: '/store/list',
                            name: '商品列表',
                            showCode: "store_list"
                        },
                        {
                            href: '/store/orderList',
                            name: '订单列表',
                            showCode: "store_orderList"
                        },
                        {
                            href: '/store/package',
                            name: '包裹列表',
                            showCode: "store_package"
                        }
                    ]
                },
                {
                    href: '/pause',
                    icon: 'el-icon-s-platform',
                    name: '卡首屏',
                    children: [
                        {
                            href: '/dy-first-page',
                            name: '抖音卡首屏',
                            showCode: "dy-first-page"
                        },
                        {
                            href: '/checkNum/firstPause',
                            name: '关键词卡首屏',
                            showCode: "checkNum_firstPause"
                        },
                        {
                            href: '/checkNum/similarImport',
                            name: '找相似入口',
                            showCode: "checkNum_similarImport"
                        },
                        // {
                        //     href: '/foreignTao',
                        //     name: '洋淘秀卡首屏'
                        // },
                        {
                            href: '/marking',
                            name: '旺旺强行打标',
                            showCode: "marking"
                        },
                        {
                            href: '/pddScreen',
                            name: '拼多多打标',
                            showCode: "pddScreen"
                        },
                        {
                            href: '/tbmobile/guess',
                            name: '猜你喜欢',
                            showCode: "tbmobile_guess"
                        },
                        {
                            href: '/head_screen/recommend',
                            name: '卡首屏系列',
                            showCode: "head_screen_recommend"
                        },
                    ]
                },
                {
                    href: '/tbmobile',
                    icon: 'el-icon-mobile',
                    name: '手淘卡屏',
                    children: [
                        {
                            href: '/tbmobile/nicegoods',
                            name: '有好货',
                            showCode: "tbmobile_nicegoods"
                        },
                        {
                            href: '/tbmobile/dayshops',
                            name: '每日好店',
                            showCode: "tbmobile_dayshops"
                        },
                        {
                            href: '/tbmobile/iFashion',
                            name: 'iFashion',
                            showCode: "tbmobile_iFashion"
                        },
                        {
                            href: '/tbmobile/robshop',
                            name: '淘抢购',
                            showCode: "tbmobile_robshop"
                        },
                        {
                            href: '/tbmobile/probaby',
                            name: '亲宝贝',
                            showCode: "tbmobile_probaby"
                        },
                        // {
                        //     href: '/tbmobile/tideplay',
                        //     name: '潮玩城'
                        // },
                        // {
                        //     href: '/tbmobile/polehome',
                        //     name: '极有家'
                        // },
                        {
                            href: '/tbmobile/hbooth',
                            name: '首页钻展',
                            showCode: "tbmobile_hbooth"
                        },
                        {
                            href: '/tbmobile/globalshop',
                            name: '全球购',
                            showCode: "tbmobile_globalshop"
                        },
                        // {
                        //     href: '/head_screen/clear',
                        //     name: '手淘清仓流量'
                        // },
                        // {
                        //     href: '/head_screen/netred',
                        //     name: '手淘网红集合'
                        // },
                        {
                            href: '/head_screen/life',
                            name: '生活研究所',
                            showCode: "head_screen_life"
                        },
                        // {
                        //     href: '/head_screen/made',
                        //     name: '中国制造'
                        // },
                        // {
                        //     href: '/head_screen/pickup',
                        //     name: '品牌捡漏'
                        // },
                        // {
                        //     href: '/head_screen/tideplay',
                        //     name: '换新'
                        // },
                        // {
                        //     href: '/head_screen/polehome',
                        //     name: '汇吃'
                        // },
                        // {
                        //     href: '/tbmobile/tbeat',
                        //     name: '淘宝汇吃'
                        // },
                    ]
                },
                {
                    href: '/monitor',
                    icon: 'el-icon-s-platform',
                    name: '监控中心',
                    children: [
                        {
                            href: '/monitor/detail',
                            name: '单品详情',
                            showCode: "monitor_detail"
                        },
                        {
                            href: '/monitor/competitive',
                            name: '竞品监控',
                            showCode: "monitor_competitive"
                        },
                        {
                            href: '/monitor/price',
                            name: '价格监控',
                            showCode: "monitor_price"
                        },
                        {
                            href: '/monitor/record',
                            name: '历史记录',
                            showCode: "monitor_record"
                        },
                    ]
                },
                // {
                //     href: '/flow',
                //     icon: 'el-icon-s-marketing',
                //     name: '流量中心',
                //     children: [
                //         {
                //             href: '/flow/taobao',
                //             name: '淘宝流量提升',
                //             showCode: "flow_taobao"
                //         },
                //         {
                //             href: '/flow/pdd',
                //             name: '拼多多流量提升',
                //             showCode: "flow_pdd"
                //         },
                //         {
                //             href: '/flow/jd',
                //             name: '京东流量提升',
                //             showCode: "flow_jd"
                //         },
                //         {
                //             href: '/flow/dy',
                //             name: '抖音流量提升',
                //             showCode: "flow_dy"
                //         },
                //         {
                //             href: '/flow/tasklist',
                //             name: '任务列表',
                //             showCode: "flow_tasklist"
                //         }
                //     ]
                // },
                {
                    href: '/user',
                    icon: 'el-icon-user-solid',
                    name: '用户中心',
                    children: [
                        {
                            href: '/personCenter',
                            name: '账户中心',
                            showCode: "personCenter"
                        },
                        {
                            href: '/newsCenter',
                            name: '消息中心',
                            showCode: "newsCenter"
                        },
                        {
                            href: '/vip',
                            name: '开通会员',
                            showCode: "vip"
                        },
                        {
                            href: '/promotion-manage',
                            name: '推广管理'
                        },
                        {
                            href: '/busQuery',
                            name: '业务查询',
                            showCode: "busQuery"
                        },
                        {
                            href: '/recharge',
                            name: '充值记录',
                            showCode: "recharge"
                        },
                        // {
                        //     href: '/storeManage',
                        //     name: '店铺管理',
                        //     showCode: "storeManage"
                        // },
                    ]
                }
            ],
            qrCodeDialog: {
                exData: {},
                show: false,
            },
            pushQrCodeDialog: {
                exData: {},
                show: false,
            },
        }
    },
    methods: {
        showtime,
        handleOpen(key, keyPath) {
        },
        handleClose(key, keyPath) {
        },
        regSuccess() {
            this.modal.register = false
            this.modal.login = false
            if (this.$route.query.aff) {
                this.$router.push({ query: {} })
                localStorage.aff = ''
            }
            this.$alert('这是一段内容', '注册成功', {
                confirmButtonText: ' 立即使用',
                dangerouslyUseHTMLString: true,
                message: this.isMainSite ? '亲恭喜你注册成功了，<br>充值满10元有机会获得7天免费会员（当天即可享受会员折扣和免费次数），请联系微信客服' : '恭喜你注册成功了',
                center: true,
                callback: action => {
                    if (action === 'confirm') {
                        this.$router.push('/home')
                    }
                }
            })
        },
        handleCommand(command) {
            if (command == 'logout') {
                this.logout()
            }
            if (command == 'personCenter') {
                this.$router.push('/personCenter')
            }
            if (command == 'newsCenter') {
                this.$router.push('/newsCenter')
            }
        },
        logout() {
            this.$store.dispatch('setLogout')
            setTimeout(() => {
                this.$router.push('/home')
            }, 500)
        },
        openRecharge() {
            window.open('/recharge')
        },
        openReg() {
            this.modal.register = true
        },
        openLogin(info = "") {
            this.modalInfo.login = info;
            this.modal.login = true
        },
        loginSuccess(info) {
            // TODO: 登录成功后触发socket
            // this.setSocket();
            this.$refs.socket.setSocket();
            if (info === "beian") {
                this.$router.replace({
                    path: '/elTool',
                })
            }
        },

        goWmt() {
            let token = '';

            if (this.isToolMainSite()) {
                let CookieToken = Cookies.get('_vt')
                let localToken = localStorage.token

                if (CookieToken && CookieToken !== localToken) {
                    localStorage.token = CookieToken
                }
                if (CookieToken) {
                    token = CookieToken
                } else {
                    token = localStorage.token
                }
            } else {
                token = localStorage.token
            }

            if (this.configData.wmt_host) {
                window.open(`http://${this.configData.wmt_host}/wmt-vt/login-with?token=${token}`)
            } else {
                window.open(`/wmt-vt/login-with?token=${token}`)
            }

        },

        topNavClick(item) {
            this.$router.push({
                path: item.href
            })
        },

        async showNav() {
            try {
                this.navLoading = true
                let res = await this.$fetch('/tool/apps/get-nav-list', {})

                if (res.code === 0) {
                    this.navShowList = res.data;
                    this.navShowListHasErr = false;
                }
            } catch (e) {
                // 报错了, 直接返回全部数据
                this.navShowListHasErr = true;
            } finally {
                this.navLoading = false
            }
        },
    },
    mounted() {
        window.showQrCode = (url) => {
            this.qrCodeDialog.exData = { url }
            this.qrCodeDialog.show = true
        }

        window.showPushQrCode = (info, title = "推送成功") => {
            this.pushQrCodeDialog.exData = { info, title }
            this.pushQrCodeDialog.show = true
        }

        this.showNav(); // 渲染菜单

        if (this.getQueryVariable('', 'loginPhone')) {
            this.modal.login = true
        }
        setTimeout(() => {
            if (this.$route.query.aff) {
                this.modal.register = true
                localStorage.aff = this.$route.query.aff
            }
        }, 1000)

        if (
            this.configData &&
            this.configData.proxy_info &&
            this.configData.proxy_info.site_title
        ) {
            document.title = this.configData.proxy_info.site_title
        }

        if (
            this.configData &&
            this.configData.proxy_info &&
            this.configData.proxy_info.site_ico
        ) {
            document.querySelector('link[rel="icon"]').href
                = "http://" + this.configData.static_url + "/" +  this.configData.proxy_info.site_ico
        }

        this.$bus.on('openReg', configData => this.openReg(configData))
        this.$bus.on('openLogin', () => this.openLogin())

        window.openLogin = (info = '') => {
            this.openLogin(info)
        }
        window.openReg = () => {
            this.openReg()
        }

        if (this.$store.state.userData.uid) {
            this.$refs.socket.setSocket();
        }

        // 在进入 路由拦截的那几个路由的时候, 如果要求打开登录框, 则这里手动打开, 为刷新数据登录后还刷新了页面
        if (this.$store.state.loginModal) {
            this.openLogin()
        }

        // 分站开分站页面不用弹出登录账号的弹框
        if (window.location.href.indexOf("/branch-register") === -1) {
            this.$store.dispatch('upDateUser') // 更新一次用户信息
        }
    },
    watch: {
        configData(n, o) {
            if (n.proxy_info && n.proxy_info.site_title) {
                document.title = n.proxy_info.site_title
            }

            if (n.proxy_info && n.proxy_info.site_ico) {
                document.querySelector('link[rel="icon"]').href
                = "http://" + n.static_url + "/" +  n.proxy_info.site_ico
            }
        },
        '$route.path'(n, o) {
            this.mobile_menu = false
        },
        loginModal(newValue, oldValue) {
            this.$confirm('登录帐号', {
                message:
                    '登录帐号后查询可保存查询数据，还能上传举报黑号',
                title: '请登录帐号查询',
                center: true,
                dangerouslyUseHTMLString: true,
                distinguishCancelAndClose: true,
                confirmButtonText: '登录',
                cancelButtonText: '没有账号？注册'
            })
                .then(() => {
                    this.openLogin()
                })
                .catch(action => {
                    if (action === 'cancel') {
                        this.modal.register = true
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    background: #2c4167;
    height: 70px;
    line-height: 70px;
    color: #fff;

    .top-nav {
        margin: 0 14px;

        &.router-link-active {
            // color: #c70026 !important;
        }
    }

    @media (max-width: 1820px) {
        .header-extra {
            display: none;
        }
    }

    .top-vip {
        height: 20px;
        position: relative;
        margin-left: 5px;
    }

    .img {
        img {
            max-width: 164px;
            max-height: 70px;
        }

        span {
            margin-top: 5px;
            line-height: 18px;
        }

        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 165px;
        height: 70px;
        background: #f13d63;
        text-align: center;
    }

    .right {
        padding-right: 20px;

        &>* {
            margin: 0 10px;
        }
    }

    .drop {
        display: inline;
        color: #fff !important;
    }
}

.nav-it {
    position: relative;
    top: -8px;
    left: -6px;
    height: 16px;
}

.iconhot {
    left: 0px;
}

.service {
    position: absolute;
    top: 400px;
    right: 0;
    z-index: 999;
    padding: 20px 0;
    background-color: #409eff99;

    .scaling {
        writing-mode: vertical-rl;
    }

    .service-card {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-right: 20px;
        padding-left: 10px;

        img {
            width: 20px;
            position: relative;
            top: -1px;
        }
    }
}

.sider {
    padding-top: 50px;
    width: 164px;
    position: absolute;
    top: 70px;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
    background-color: #294265;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    z-index: 1111;

    &::-webkit-scrollbar {
        width: 0 !important;
    }

    .el-menu {
        background: transparent;
    }
}

.btn-service {
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 999;
    font-size: 24px;
}
</style>
<style lang="scss">
.el-menu {
    background: transparent !important;
}

.el-submenu__title:focus,
.el-submenu__title:hover {
    background-color: #c7002612 !important;
}

.el-menu-item.is-active {
    color: #c70026 !important;
    border-left: 3px solid #c70026;

    i {
        color: #c70026 !important;
    }
}

.el-menu-item:focus,
.el-menu-item:hover {
    background-color: #c7002612 !important;
}

.el-menu-item {
    color: #fff !important;
}

.el-submenu__title i {
    color: #909399;
}

.nav-sider {
    height: 100%;
    border: none !important;
    font-weight: normal;
}

.service .el-card__body {
    padding-left: 10px;
    padding-right: 0;
}

.slide-service-enter-active {
    transition: all 1s ease;
}

.slide-service-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-service-enter,
.slide-service-leave-to

/* .slide-fade-leave-active for below version 2.1.8 */
    {
    transform: translateX(20px);
    opacity: 0;
}
</style>
